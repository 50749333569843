@font-face {
  font-family: Inter;
  src: url('./Resources/Fonts/Inter-Regular.ttf')
    format('opentype');
}

@font-face {
  font-family: InterBold;
  src: url('./Resources/Fonts/Inter-Bold.ttf')
    format('opentype');
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: Inter, InterBold, -apple-system,
    BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

code {
  font-family: Inter, InterBold, -apple-system,
    BlinkMacSystemFont, sans-serif;
}

h1 {
  color: #1c1c1c;
  text-align: center;
  font-family: InterBold;
  font-size: 28px;
  margin: 0;
}

.react-datepicker-wrapper {
  display: block;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__day--selected:hover {
  background-color: #008945;
}
